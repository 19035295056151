import React, { useContext, useEffect, useState } from 'react';
import './styleUi.css';
import { pageContextManager } from '../../App';
import LogoWithChar from '../LogoWithChar/LogoWithChar';
import { getAgoTime, limit_content } from '../ComonFunc/ComonFunc';
import Navbar from '../Navbar/Navbar';
import Popup from '../Popup/Popup';
import { useNavigate } from 'react-router-dom';

const Inbox = () => {
    const [getAllConversations, setAllConversations] = useState([]);
    const [IsSelectAll, setIsSelectAll] = useState(false);
    const [searchQuery, setSearchQuery] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [popupBool, setPopUpBool] = useState(false);
    const [getPageList, setPageList, getPageInfo, setPageInfo, selectedConversation, setSelectedConversation] = useContext(pageContextManager);
    const [getConversation, setConversation] = useState({});
    const [getLoading, setLoading] = useState(false);
    const [increaseData, setIncreaseData] = useState(0);
    const intialDataShow = 25;
    const indexOfFirstItem = 0;
    const currentTotalItems = intialDataShow + increaseData;
    const [totalItems, setTotalItems] = useState(null);
    const currentData = searchQuery.length > 0 ? searchQuery.slice(indexOfFirstItem, currentTotalItems) : getAllConversations.length > 0 && getAllConversations.slice(indexOfFirstItem, currentTotalItems);

    const navigate = useNavigate();
    function getAllConversationsFunc() {
        getConversations(getPageInfo.id, getPageInfo.access_token);
    }

    useEffect(() => {
        getAllConversations && setTotalItems(getAllConversations.length);
    }, [getAllConversations])

    let nextPageURL;

    function getConversations(pageID, pageAccessToken) {
        // let endpoint = `/${pageID}/conversations?fields=participants{id}&access_token=${pageAccessToken}`;
        let endpoint = `/${pageID}/conversations?fields=participants{id,picture.type(large){url}},messages{message,created_time,from{id,name,picture}}&access_token=${pageAccessToken}`;
        console.log("nextPageURL 1 " + nextPageURL)

        if (nextPageURL) {
            endpoint = nextPageURL;
        }

        window.FB.api(endpoint, function (response) {
            if (response && !response.error) {
                console.log("response", response);
                // allConversations = allConversations.concat(response.data); // add the new page of results to the array
                response.data.map(msgData => {
                    // console.log(msgData)
                    const messageData = { ...msgData, isSelect: false }
                    setAllConversations((getAllConversations) => [...getAllConversations, messageData])
                })
                // setAllConversations(getAllConversations => [...getAllConversations, response.data])
                nextPageURL = response.paging.next;
                console.log("nextPageURL " + nextPageURL)

                if (nextPageURL) {
                  getConversations(pageID, pageAccessToken); // recursively call getConversations to retrieve the next page
                } else {
                  //console.log(getAllConversations); // handle the final result
                }
            }
        });
    }

    const selectedClientFunc = (clientId) => {
        const updatedConversations = getAllConversations.map(conversation =>
            conversation.id === clientId
                ? { ...conversation, isSelect: true }
                : conversation
        );
        setAllConversations(updatedConversations);
    };
    // const selectedClientFunc = (clientData) => {

    //     setSelectedConversation((data) => {
    //         const exists = data.some((conversation) => conversation.id === clientData.id);
    //         if (exists) {
    //           // Remove clientData if it already exists in the array
    //           return data.filter((conversation) => conversation.id !== clientData.id);
    //         }
    //         // Add clientData if it doesn't exist in the array
    //         return [...data, clientData];
    //       });
    // }

    const onchangeAllConversation = () => {

        if (!IsSelectAll) {
            const updatedConversations = getAllConversations.map(conversation => { return { ...conversation, isSelect: true } });
            setAllConversations(updatedConversations);
        } else {
            const updatedConversations = getAllConversations.map(conversation => { return { ...conversation, isSelect: false } });
            setAllConversations(updatedConversations);
        }

        setIsSelectAll(!IsSelectAll);
    }
    // const onchangeAllConversation = () =>{
    //     if(getAllConversations.length == selectedConversation.length){
    //         setSelectedConversation([])
    //     }else{
    //         setSelectedConversation([])
    //         let converSation = []; // Assuming this is the initial state or fetched data.
    //         getAllConversations.map((item) => {
    //             converSation.push(item.participants.data[0])
    //         })
    //         setSelectedConversation(converSation)
    //     }
    // }

    const handleSearchChange = (event) => {
        // setSearchQuery(event.target.value);
        const dataValue = event.target.value;
        const filteredArray = getAllConversations.filter(item =>
            item.participants.data[0].name.toLowerCase().includes(dataValue.toLowerCase())
        );
        setSearchQuery(filteredArray)
    };


    useEffect(() => {
        getAllConversationsFunc()
        setSelectedConversation([])
    }, [])

    const sendMessage = () => {
        console.log("messageText", messageText);
        console.log("selectedConversation", getAllConversations.filter(item => item.isSelect).length);
        console.log(Object.keys(getConversation).length === 0 && getConversation.constructor === Object)
        setSelectedConversation([]);
        if (getAllConversations.filter(item => item.isSelect).length > 0) {
            const conversation = getAllConversations.filter(item => item.isSelect)
            const sendingData = { conversation: conversation, messageText: messageText };
            setSelectedConversation(sendingData);
            navigate("/sending-message");
        } else if (Object.keys(getConversation).length > 0 && getConversation.constructor === Object) {
            const conversation = { ...getConversation, isSelect: true }
            const sendingData = { conversation: [conversation], messageText: messageText };
            setSelectedConversation(sendingData);
            navigate("/sending-message");
        } else {
            // alert("Please select conversation")
            setPopUpBool(true);
        }

    }

    const callBackPopup = (data) => {
        setPopUpBool(data);
    }
    useEffect(() => {
        const handleScroll = () => {
            const inboxList = document.getElementById('userInboxList');
            // console.log("scrollTop :", inboxList.scrollTop + inboxList.clientHeight, "scroll height ", inboxList.scrollHeight);

            if ((inboxList.scrollTop + inboxList.clientHeight) + 10 >= inboxList.scrollHeight) {
                console.log("totalItem ", totalItems, " currentTotalItems ", currentTotalItems)
                if (totalItems >= currentTotalItems) {
                    setLoading(true);
                    setTimeout(() => {
                        setIncreaseData((prev) => prev + 10);
                        setLoading(false);
                    }, 1500);
                }
            }
        };

        const inboxList = document.getElementById('userInboxList');
        inboxList.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            inboxList.removeEventListener('scroll', handleScroll);
        };
    }, [totalItems, currentTotalItems]);
    return (
        <>
            {console.log("getAllConversations", getAllConversations)}
            {
                console.log("getConversation", getConversation)
            }
            {
                console.log("SelectedConversation ", selectedConversation)
            }
            {
                console.log("messageText", messageText)
            }

            <Navbar />
            <div className='container mx-auto'>
                <div className='grid grid-cols-3 gap-3'>
                    <div className='col-span-1 flex flex-col'>
                        <div className='flex flex-col'>
                            <div className='flex flex-col gap-3'>
                                <div className='flex items-center gap-3 pt-3'>
                                    <img className='w-16' src={getPageInfo?.picture?.data?.url} />
                                    <h3>{getPageInfo?.name}</h3>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='srcWrap grid grid-cols-12'>
                                        <div className='src col-span-9 flex flex-col relative ' >
                                            <i class="fa-solid fa-magnifying-glass self-center text-xs text-gray-400 absolute top-[50%] left-[10px]" style={{ transform: 'translateY(-50%)' }}></i>
                                            <input onChange={handleSearchChange} placeholder='search' type='text' className='pl-8 py-1 w-full focus:outline-none' />
                                        </div>
                                        <button className='srcBtn px-2 col-span-3 bg-sky-700 text-white font-bold'>Search</button>
                                    </div>
                                </div>
                                <div className="grid grid-cols-[auto,100px] bg-blue-100 p-2">
                                    <div class="flex gap-4">
                                        <input onChange={onchangeAllConversation} type='checkbox' />
                                        <span>Select {getAllConversations.filter(item => item.isSelect).length} conversations</span>
                                    </div>
                                    {/* <div class="self-end flex flex-row gap-4 justify-end">
                                    <span className='font-bold'>Filter</span>
                                    <select className='bg-transparent'>
                                        <option></option>
                                        <option>A</option>
                                        <option>B</option>
                                        <option>C</option>
                                        <option>D</option>
                                    </select>
                                </div> */}
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div className='flex flex-col'>
                            <h2 className="text-xl font-bold text-left">Inbox</h2>
                            <div className='flex flex-col'>
                                <div class="flex flex-col gap-4 pt-4">
                                    <div id='userInboxList' className='flex flex-col gap-2 pl-2 overflow-auto'>

                                        {currentData && currentData.map((data, index) =>

                                            <div className="grid grid-cols-[11px,auto] gap-4 relative">
                                                <input
                                                    checked={data.isSelect}
                                                    onChange={() => selectedClientFunc(data.id)}
                                                    type='checkbox' />
                                                {/* <img className='self-center' src={require('./img/avater.png')} /> */}
                                                <div
                                                    onClick={() => { setConversation(data) }}
                                                    className='cursor-pointer flex gap-4'>
                                                    <LogoWithChar name={data.participants.data[0].name} />
                                                    <div className="flex flex-col items-start">
                                                        <p className='font-bold text-sm'>{data.participants.data[0].name}</p>
                                                        {/* <p className='font-bold'>Hi there!</p> */}
                                                        <p className="text-gray-600 text-xs">{limit_content(data.messages.data[0].message, 50)}</p>
                                                    </div>
                                                    <span class="absolute top-0 right-0 text-sm text-gray-500">{getAgoTime(data.messages.data[0].created_time)}</span>
                                                </div>
                                            </div>
                                        )}
                                        {getLoading &&
                                            <div className='flex justify-center items-center'>
                                                <p className='text-3xl font-semibold text-center'>Loading...</p>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-2 bg-gray-300 flex flex-col'>
                        <div className="bg-gray-200 p-5 flex flex-col gap-4 shadow-sm">
                            <h2 className="text-2xl font-bold text-left">NEW BULK MESSAGE</h2>
                            {/* <ul className="flex flex-col items-start gap-2">
                                <li>Select labesl to choose who to send a message</li>
                                <li>Use “all recipients” to select everyone on your pages inbox</li>
                                <li>Use “no label” to select the peoples with no labels</li>
                                <li>You can also use labels to exclude people</li>
                            </ul>
                            <div className="flex flex-col gap-2">
                                <h4 class="text-left font-bold">Labels</h4>
                                <div class="flex flex-row gap-6">
                                    <button class="bg-gray-300 p-1.5 px-2 text-sm rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                                    <button class="bg-gray-300 p-1.5 px-2 text-sm rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                                    <button class="bg-gray-300 p-1.5 px-2 text-sm rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                                    <button class="bg-gray-300 p-1.5 px-2 text-sm rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                                </div>
                            </div> */}
                        </div>
                        <div className='relative h-full overflow-auto'>

                            <div className='flex flex-col-reverse gap-2 p-3 absolute top-0 left-0 w-full h-[79%] overflow-auto'>

                                {
                                    getConversation && getConversation?.messages?.data?.map(item =>
                                        item.from.id === getPageInfo.id ?
                                            <div className='flex items-center justify-end self-end gap-3 bg-gray-500 text-slate-100 p-3 rounded-md w-[90%]'>
                                                <p dangerouslySetInnerHTML={{ __html: item.message.replace(/\n/g, '<br />') }}></p>
                                                {/* <LogoWithChar name={item.from.name} /> */}
                                            </div>
                                            :
                                            <div className='flex items-center gap-3 bg-gray-100 p-3 rounded-md w-[90%]'>
                                                <LogoWithChar name={item.from.name} />
                                                <p dangerouslySetInnerHTML={{ __html: item.message.replace(/\n/g, '<br />') }}></p>
                                            </div>
                                    )
                                }
                            </div>
                            <div className='absolute bottom-0 w-full grid grid-cols-6 py-3 px-5'>
                                {/* <input onChange={(e) => setMessageText(e.target.value)} type='text' className='col-span-5 py-1 focus:border-transparent' /> */}
                                <textarea id="w3review" name="w3review" rows="3" cols="50"
                                    onChange={(e) => setMessageText(e.target.value)} className='px-2 resize-none	col-span-5 py-1 focus:border-transparent'
                                />
                                <button onClick={sendMessage} className='col-span-1 bg-slate-900 text-white font-bold py-1 text-xs'>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                popupBool &&
                <Popup
                    title='Alert'
                    message='Please select conversation to send a message'
                    callBackPopup={callBackPopup}
                />
            }
        </>
    );
};

export default Inbox;