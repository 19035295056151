import React, { useEffect, useState, useContext } from 'react';
import { pageContextManager, userContextManager } from '../../App';
import { Link, useNavigate  } from 'react-router-dom';
// import {limit_content} from '../ComonFunc/ComonFunc';

const PageList = () => {
  const [getUser, setUser] = useContext(userContextManager)
  const [getPageList, setPageList, getPageInfo, setPageInfo] = useContext(pageContextManager);
  const [getConversation, setConversation] = useState([]);
  const [getAllConversations, setAllConversations] = useState([]);

  let nextPageURL;
  const navigate = useNavigate();


  const getUserPages = () => {
    let endpoint = `/${getUser.authResponse.userID}/accounts?fields=id,name,picture.type(normal),cover,about,access_token&access_token=${getUser.authResponse.accessToken}`;

    if (nextPageURL) {
      console.log("endpoint")
      endpoint = `/${getUser.authResponse.userID}/accounts?fields=id,name,picture.type(normal),cover,about,access_token&access_token=${getUser.authResponse.accessToken}&after=${nextPageURL}`;;
      console.log(endpoint)
    }
    const limit_content = (str, limit) => {
      let strFormat = "";
      if (str.length > limit) {
        strFormat = str.substring(0, limit) + '...'
      } else {
        strFormat = str;
      }

      return strFormat;
    }

    window.FB.api(endpoint,
      function (response) {
        if (response && !response.error) {
          console.log(response)
          response.data.map(data => {
            setPageList(getPageList => [...getPageList, data])
            // console.log(data)
            // getPageProfilePic(data.id, data)
          })
          // nextPageURL = response.paging.cursors.after;
          nextPageURL = response.paging.next;

          console.log("nextPageURL " + nextPageURL)
          if (nextPageURL) {
            console.log("next page")
            getUserPages();
          } else {
            //console.log(getAllConversations); // handle the final result
          }
          /* handle the result */
        }
      }
    );
  };


  const getPageProfilePic = (pageId, data) => {
    window.FB.api(
      `/${pageId}/picture`,
      {
        "redirect": "0"
      },
      function (response) {
        if (response && !response.error) {
          const pageData = {
            data: data,
            profile: response.data.url
          }
          setPageList(getPageList => [...getPageList, pageData])

          /* handle the result */
        }
      }
    );
  }

  const onChangePageInfo = (data) => {
    setPageInfo(data);
    navigate('/inbox')
  }
  useEffect(() => {
    getPageList.length == 0 && getUserPages()
    console.log('i fire once');
  }, [])

  return (
    <div>
      {console.log(getPageList)}
      <p>Total Page : {getPageList.length}</p>
      <div className='container mx-auto'>
      <div className='flex flex-col md:grid grid-cols-6 gap-10'>
        {getPageList.map((data, index) =>
          <div className='md:h-40 relative shadow-lg'>
            <div className='relative h-[230px] md:h-[132px]'>
              {/* <input onChange={() => onChangePageInfo(data)} className='absolute top-0 right-0' type="radio" id={'page_' + index} name="fav_language" value="HTML" /> */}
              <label className='w-full h-full flex justify-center items-center p-1' for={'page_' + index}>
                <img src={data.picture.data.url} alt="" className='w-full h-full absolute left-0 top-0' />
              </label>
            </div>
            <div className='flex bg-slate-200 text-sm p-1 justify-between items-center'>
              <span className='text-xs'>{data?.name?.length > 15 ? data.name.substring(0, 15) + '...': data.name}</span>
              <span onClick={() => onChangePageInfo(data)} className='bg-sky-800 cursor-pointer font-bold text-white text-[10px] px-2 rounded-sm'>View Page</span>
            </div>
          </div>
        )}
      </div>
      </div>

{/* 
      <Link className='bg-green-800 text-white px-5 py-2' to={'/inbox'}>
        Go to
      </Link> */}
    </div>
  );
};

export default PageList;