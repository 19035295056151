import { createContext, useEffect, useState } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import PageList from './Component/PageList/PageList';
import FacebookLogin from './Pages/FacebookLogin/FacebookLogin'
import InboxManager from './Component/InboxManager/InboxManager';
import InboxUi from './Component/InboxManager/InboxUi';
import Inbox from './Component/InboxManager/Inbox';
import HomePage from './Pages/HomePage/HomePage';
import Dashboard from './Component/Dashboard/Dashboard';
import SendingPage from './Pages/SendingPage/SendingPage';
import PrivacyPolicy from './Pages/Privacy/Privacy';
import TermsOfService from './Pages/Terms/Terms';

export const userContextManager = createContext();
export const pageContextManager = createContext();

function App() {

  const [getUser, setUser] = useState({});
  const [getPageList, setPageList] = useState([]);
  const [getPageInfo, setPageInfo] = useState({})
  const [selectedConversation, setSelectedConversation] = useState([]);

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '1643667912725250',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0'
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  return (
    <userContextManager.Provider value={[getUser, setUser]}>
      <pageContextManager.Provider value={[getPageList, setPageList, getPageInfo, setPageInfo, selectedConversation, setSelectedConversation]}>
        <div className="App">
          {/* <Navbar /> */}

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/signin" element={<FacebookLogin />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/page-list' element={<PageList />} />
            <Route path='/inbox-message' element={<InboxManager />} />
            <Route path='/inbox' element={<Inbox />} />
            <Route path="/InboxUi" element={<InboxUi />} />
            <Route path="/sending-message" element={<SendingPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/tos" element={<TermsOfService />} />
          </Routes>
        </div>
      </pageContextManager.Provider>
    </userContextManager.Provider>
  );
}

export default App;
