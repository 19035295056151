import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { userContextManager } from '../../App';

function FacebookLogin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [userInfo, setUserInfo] = useState(null);
  const [getUser, setUser] = useContext(userContextManager);

  const navigate = useNavigate();
  
  const loginStatus = () => {
    window.FB.getLoginStatus(function (response) {
      console.log(response);
      if (response.authResponse) {
        setUser(response)
        setIsLoggedIn(true);
        fetchUserInfo();
        console.log("connected");
      } else {
        loginWithFacebook()
      }
    });
  }

  const loginWithFacebook = () => {
    window.FB.login(function (response) {
      console.log(response)
      if (response.authResponse) {
        console.log("login success")
        setUser(response)
        setIsLoggedIn(true);
        fetchUserInfo();
      } else {
        setIsLoggedIn(false);
      }
    }, {
      auth_type: 'reauthorize',
      scope: 'pages_show_list,pages_manage_metadata,pages_read_engagement,pages_manage_posts,pages_manage_engagement,pages_messaging,business_management,public_profile',
      return_scopes: true
    });
  };

  const fetchUserInfo = () => {
    window.FB.api('/me', {fields: 'name, picture'}, function (response) {
      console.log(response)
      // setUserInfo(response);
      if (!response || response.error) {
        console.log('Error occured');
      } else {
        setUser((data)=>{
          return {...data,userInfo:response}
        })
        navigate('/page-list')
      }

    });
  };

  const logout = () => {
    window.FB.logout(function (response) {
      setIsLoggedIn(false);
      setUser(null);
    });
  };

  const checkUserExits = () => {
    if (getUser.status == "connected") {

      setIsLoggedIn(true);
      fetchUserInfo();
    }

  }
  useEffect(() => {
    checkUserExits()
  }, [])
  return (
    <>
          {/* <button className='bg-slate-400 p-3' onClick={logout}>Logout</button> */}
    {
      console.log(getUser)
    }
      {/* {isLoggedIn && userInfo && (
        <div>
          <p>Welcome {userInfo.name}!</p>
          <button className='bg-slate-400 p-3' onClick={logout}>Logout</button>
        </div>
      )}

      {!isLoggedIn && (
        <button className='bg-slate-400 p-3' onClick={loginStatus}>Login with Facebook</button>
      )}
      <Link to="/page-list">Page List</Link> */}
      <div className='flex flex-col items-center justify-center w-full h-screen bg-sky-700'>
        <div className="w-80 bg-white shadow-lg p-6 rounded-md">
          <div className='flex flex-col gap-3 pb-5'>
            <h1 className='text-3xl font-bold'>toolAre</h1>
            <span className='text-[10px]'>SOCIAL INBOX MARKETING IS BORN</span>
            <p className='text-sm'>Send targeted inbox messages by thousands to the people that has already started a conversation with your page.</p>
          </div>
          <div>
            <button onClick={loginStatus} className="flex items-center justify-center w-full px-4 py-2 mt-2 space-x-3 text-sm text-center text-white transition-colors duration-200 transform border rounded-lg btn btn-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
              <span class="text-sm text-white">Continue with Facebook</span></button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FacebookLogin;
