import React from 'react';

const HowItWork = () => {
    return (
        <>
            <div id='howitwork' className='pt-44 pb-36'>
                <div className='container mx-auto px-5 md:px-0'>
                    <div className='flex flex-col gap-3 lg:w-[800px] text-left'>
                        <h2 className='text-3xl font-bold text-sky-800'>HOW IT WORKS</h2>
                        <p>With toolAre, you can efficiently manage your interactions. Respond to multiple comments simultaneously, assign custom labels to your users, and easily filter your inbox by these labels or specific keywords. Need to send a message to a group? No problem! Select your users and send them an inbox message with just one click. Streamlining your communication has never been this easy or effective. Awesome, right ?</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HowItWork;