import React, { useEffect, useState, useContext } from 'react';
import { pageContextManager, userContextManager } from '../../App';

const InboxManager = () => {
  const [getUser, setUser] = useContext(userContextManager)
  // const [getPageList, setPageList] = useState([]);
  const [getConversation, setConversation] = useState([]);
  const  [getAllConversations,setAllConversations] = useState([]);
  const [getPageList, setPageList, getPageInfo, setPageInfo] = useContext(pageContextManager);


  const conversationListFunc = () => {
    console.log(getPageInfo.id);
    window.FB.api(
      `/${getPageList.id}/conversations?fields=participants{id}&access_token=${getPageInfo.access_token}`,
      function (response) {
        console.log(response);

        if (response && !response.error) {
          console.log(response);
          setConversation(response);
          /* handle the result */
        }
      }
    );
  }


  const sendMassageFunc = () => {

    window.FB.api(
      `/me/messages`,
      'POST',
      {
        recipient: { "id": "2866088973433440" },
        // recipient: { "id": "2866088973433440"},
        message: { "text": "Back To The Sunnah..." },
        messaging_type: 'MESSAGE_TAG',
        tag: 'CONFIRMED_EVENT_UPDATE',
        access_token: `${getPageInfo.access_token}`
      },
      function (response) {
        if (response && !response.error) {
          console.log("Message sent successfully");
          console.log(response);
        } else {
          console.error("Error sending message", response.error);
        }
      }
    );
    // window.FB.api(
    //   `/${getPageInfo.id}/messages`,
    //   'POST',
    //   {
    //     recipient: { "id": "2866088973433440" },
    //     // recipient: { "id": "2866088973433440"},
    //     message: { "text": "Back To The Sunnah" },
    //     access_token: `${getPageInfo.access_token}`
    //   },
    //   function (response) {
    //     console.log(response);
    //   }
    // );

  }


  function getAllConversationsFunc() {
    getConversations(getPageInfo.id, getPageInfo.access_token);
  }
  let nextPageURL;

  function getConversations(pageID, pageAccessToken) {
    // let endpoint = `/${pageID}/conversations?fields=participants{id}&access_token=${pageAccessToken}`;
    let endpoint = `/${pageID}/conversations?fields=participants{id,picture.type(large){url}},messages{message,created_time,from{id,name}}&access_token=${pageAccessToken}`;
    console.log("nextPageURL 1 " + nextPageURL)

    if (nextPageURL) {
      endpoint = nextPageURL;
    }

    window.FB.api(endpoint, function (response) {
      if (response && !response.error) {
       console.log("getConversations", response);
        // allConversations = allConversations.concat(response.data); // add the new page of results to the array
        response.data.map(msgData =>{
          // console.log(msgData)
         setAllConversations((getAllConversations) => [...getAllConversations, msgData])
        })
        // setAllConversations(getAllConversations => [...getAllConversations, response.data])
        nextPageURL = response.paging.next;
        console.log("nextPageURL " + nextPageURL)
        if (nextPageURL) {
          getConversations(pageID, pageAccessToken); // recursively call getConversations to retrieve the next page
        } else {
          //console.log(getAllConversations); // handle the final result
        }
      }
    });
  }


  return (
    <div>
      {console.log(getAllConversations)}
      <button className='bg-slate-400' onClick={conversationListFunc}>Conversation</button><br></br>
      {/* <button onClick={getParticipentId}>Perticipent id </button><br/> */}
      <button className='bg-red-300' onClick={getAllConversationsFunc}>All Converation</button><br></br>
      {/* <button onClick={seeConversation}>See Converation</button> */}
      <button className='bg-slate-300' onClick={sendMassageFunc}>Send</button>
      <div>
        <ol>

        
        {getAllConversations.map(d=>
          <li>{d.participants.data[0].name}</li>
          )}
          </ol>
      </div>
    </div>
  );
};

export default InboxManager;
