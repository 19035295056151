import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { userContextManager } from '../../App';

const Navbar = ({ mode }) => {
  const [getUser, setUser] = useContext(userContextManager);


  return (
    < >
    {
      console.log("getsuer", getUser)
    }
      <div className="flex flex-wrap place-items-center bg-sky-600 z-20 relative">
        <section className="w-full relative mx-auto">
          <nav className="container mx-auto flex justify-between text-gray-100  px-5 md:px-0">
            <div className="py-3 flex w-full items-center">
              <Link to="/" className="text-2xl font-bold font-heading">toolAre</Link>
              <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
                <li><a className="hover:text-gray-200" href="#howitwork">How It Work</a></li>
                <li><a className="hover:text-gray-200" href="#feature">Feature</a></li>
                {
                   getUser && getUser.status == "connected" && <li><Link to={'/page-list'} className="hover:text-gray-200" href="#feature">Page List</Link></li>
                }
                {/* <li><a className="hover:text-gray-200" href="#pricing">Pricing</a></li> */}
                {/* <li><Link to={"/inbox-message"} className="hover:text-gray-200" href="#">inbox message</Link></li> */}
                {/* <li><Link to={"/InboxUi"} className="hover:text-gray-200" href="#">InboxUi</Link></li> */}
                {/* <li><Link to={"/inbox"} className="hover:text-gray-200" href="#">inbox</Link></li> */}
                {/* <li><a className="hover:text-gray-200" href="#">Contact Us</a></li> */}
              </ul>
              <div className="hidden md:flex space-x-5 items-center">
                {/* <a className="hover:text-gray-200" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                </a>
                <a className="flex items-center hover:text-gray-200" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  <span className="flex absolute -mt-5 ml-4">
                    <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500">
                    </span>
                  </span>
                </a>
                <a className="flex items-center hover:text-gray-200" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </a> */}
                {
                  getUser && getUser.status == "connected" ? "" : <Link to='/signin' className="btn btn-solid-primary">Sign In</Link>
                }
                
              </div>
            </div>
  

{/* mobile menu */}
              <div className='flex md:hidden justify-center items-center'>
              <div className="dropdown-container">
              <div className="dropdown">
                <div className='flex justify-end items-end'>

                <label className="px-0" tabIndex="0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white hover:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </label>
                </div>
                <div className="dropdown-menu dropdown-menu-bottom-left text-black">
                  <a className="dropdown-item text-sm">Profile</a>
                  <a tabIndex="-1" className="dropdown-item text-sm">Account settings</a>

                  {
                    getUser && getUser.status == "connected" ? <Link to={'/page-list'}tabIndex="-1" className="dropdown-item text-sm">Page List</Link> : <Link  to='/signin' tabIndex="-1" className="dropdown-item text-sm">Sign In</Link>
                  }
                </div>
              </div>
            </div>
              </div>
          </nav>

        </section>
      </div>
    </>
  );
};

export default Navbar;