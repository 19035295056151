import React from 'react';
import Navbar from '../../Component/Navbar/Navbar';
import HowItWork from '../../Component/HowItWork/HowItWork';
import Features from '../../Component/Features/Features';
import PricingList from '../../Component/Pricing/PricingList';
import Footer from '../../Component/Footer/Footer';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (
        <>
            <div className='overflow-hidden w-full'>
                <div className='bg-sky-600 w-full h-[540px] absolute -top-11 left-0 z-0 skew-y-2'></div>
                {/* <div className='bg-sky-600 w-full h-[540px] absolute -top-11 left-0 z-0 skew-y-2'></div> */}
                <Navbar />
                <div className='w-full z-1 relative'>
                    <div className='container mx-auto  px-5 md:px-0'>
                        <div className='flex flex-col md:grid md:grid-cols-12 max-w-screen-xl gap-16 md:gap-11 pt-14 items-center'>
                            <div className='col-span-12 lg:col-span-6 text-center md:text-left flex flex-col md:grid md:grid-cols-12 gap-4 text-white'>
                                <h1 className='text-2xl md:text-3xl font-bold col-span-9 '>Connect with your customers by sending bulk messages directly from your Facebook page.</h1>
                                <p className="col-span-9">Send thousands of targeted messages to those who have already engaged with your page.</p>
                                <Link to={'/signin'} className="btn w-60 m-auto md:m-0 col-span-9 bg-emerald-500 text-white mt-3">TRY IT FREE</Link>
                            </div>
                            <div className='col-span-12 lg:col-span-6 bg-green-500'>
                                <video id="video" autoPlay loop muted class="hidden-xs" src="toolare.mp4">
                                    <source src="toolare.mp4" type="video/mp4" />
                                    <source src="toolare.ogg" type="video/ogg" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HowItWork />
            <Features />
            {/* <PricingList /> */}

            <div className='w-full py-20 relative'>
                <div className='bg-sky-600  w-full h-[330px] absolute -bottom-7 left-0 z-0 -skew-y-2'></div>
                <div className='container mx-auto z-10 relative  px-5 md:px-0'>
                    <div className="flex flex-col items-center gap-2">
                        <h1 className='text-3xl text-white font-bold'>Ready to get started?</h1>
                        <p className="text-slate-200">Try it for free—just connect with Facebook.</p>
                        <Link to={'/signin'} className="btn w-60 col-span-9 bg-emerald-500 text-white mt-3">TRY IT FREE</Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </>

    );
};

export default HomePage;